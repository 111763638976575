import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { endpoints } from 'constants/endpoints';
import { queryKeys } from 'constants/queryKeys';
import { useWeb3Onboard } from 'hooks';
import { useRouter } from 'next/router';
import { addQueryParams } from 'utils/axios';
import { getErrorMessage, handleErrorWithAuth } from 'utils/error';
import { showError } from 'utils/toast';

const PORTAL_ID = '23173042';
const FORM_ID = '31ffdea1-dc9f-47b8-82f7-e0efa548b61b';

export enum WaitlistFlowEnum {
	JOIN = 'JOIN',
	UNDER_REVIEW = 'UNDER_REVIEW',
	LOGIN = 'LOGIN',
	SIGNUP = 'SIGNUP',
}

type JoinWaitlistPayload = {
	name: string;
	email: string;
	account: string;
	wallet_name: string;
	org_name?: string;
	twitter_profile?: string;
	custom_gdpr: boolean;
};

const joinWaitlistAPI = ({
	account,
	wallet_name,
	email,
	org_name,
	twitter_profile,
	name,
	custom_gdpr,
}: JoinWaitlistPayload) =>
	axios.post(
		`https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${FORM_ID}`,
		{
			fields: [
				{
					name: 'firstname',
					value: name,
				},
				{
					name: 'email',
					value: email,
				},
				{
					name: 'twitter_profile',
					value: twitter_profile,
				},
				{
					name: 'company',
					value: org_name,
				},
				{
					name: 'wallet_name',
					value: wallet_name,
				},
				{
					name: 'wallet_address',
					value: account,
				},
				{
					name: 'custom_gdpr',
					value: custom_gdpr,
				},
			],
		}
	);

const useJoinWaitlistMutation = () => {
	return useMutation(joinWaitlistAPI, {
		onError: (error: unknown) => {
			showError(getErrorMessage(error));
		},
	});
};

export interface WaitlistStatus {
	flow: WaitlistFlowEnum;
	createOrganisation: boolean;
}

const getWaitlistStatusAPI = async ({
	walletAddress,
	referralCode,
}: {
	walletAddress?: string;
	referralCode?: string | string[];
}) => {
	const { data } = await axios.get<{
		data: WaitlistStatus;
	}>(
		addQueryParams(endpoints.waitlist.get, {
			walletAddress,
			referralCode,
		})
	);

	return {
		flow: data?.data?.flow,
		canCreateOrganisation:
			process.env.NEXT_PUBLIC_WAITLIST_ENABLED === 'true'
				? data?.data?.createOrganisation
				: true,
	};
};

const useWaitlistQuery = () => {
	const { account } = useWeb3Onboard();
	const { query } = useRouter();

	return useQuery(
		queryKeys.waitlist.all,
		() =>
			getWaitlistStatusAPI({
				walletAddress: account,
				referralCode: query.referral_code,
			}),
		{
			enabled: !!account,
			onError: handleErrorWithAuth,
		}
	);
};

export { useJoinWaitlistMutation, useWaitlistQuery };
