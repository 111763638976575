// Source - https://github.com/kelvinmaues/react-hubspot-tracking-code-hook
/**
 * The methods allowed by HubSpot tracking code
 */
export type IMethodParam =
	| 'setPath'
	| 'trackPageView'
	| 'identify'
	| 'trackEvent'
	| 'revokeCookieConsent'
	| 'addPrivacyConsentListener'
	| 'doNotTrack'
	| 'addIdentityListener'
	| 'setContentType'
	| 'refreshPageHandlers';

/**
 * The props to the main hook
 */
export type IPropsUseTrackingCode = {
	initialPath: string;
};

/**
 * The props to track event function
 */
export type IPropsUseSetTrackEvent = {
	eventId: string;
	value?: number | string;
};
export type IPushParams = [IMethodParam, (string | object)?];

/**
 * The shape of the hook
 */
export type IUseTrackingCode = {
	setContentType: (contentType: string) => void;
	setPathPageView: (path: string) => void;
	setTrackPageView: () => void;
	setIdentity: (email: string, customPropertities?: any) => void;
	setTrackEvent: ({ eventId, value }: IPropsUseSetTrackEvent) => void;
};

const useHubspotTracking = (): IUseTrackingCode => {
	const _hsq =
		typeof window !== 'undefined' && window._hsq
			? window._hsq
			: ([] as string[]);

	const setContentType = (contentType: string): void => {
		_hsq.push(['setContentType', contentType]);
	};

	const setTrackPageView = () => {
		_hsq.push(['trackPageView']);
	};

	const setPathPageView = (path: string): void => {
		// This function updates the path
		_hsq.push(['setPath', path]);
		// This function track the current updated page path
		setTrackPageView();
	};

	const setIdentity = (email: string, customPropertities?: any) => {
		_hsq.push([
			'identify',
			{
				email,
				...customPropertities,
			},
		]);
	};

	const setTrackEvent = ({ eventId, value }: IPropsUseSetTrackEvent) => {
		_hsq.push([
			'trackEvent',
			{
				id: eventId,
				value,
			},
		]);
	};

	return {
		setContentType,
		setPathPageView,
		setTrackPageView,
		setIdentity,
		setTrackEvent,
	};
};

export default useHubspotTracking;
