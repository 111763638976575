import Button from 'components/Button';
import Stack from 'components/Stack';
import { useTheme } from 'styled-components';

import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { CheckboxInput, TextInput } from 'components/Form';
import { FormContainer } from 'components/Form/FormField.styles';
import Typography from 'components/Typography';
import { queryKeys } from 'constants/queryKeys';
import { useWeb3Onboard } from 'hooks';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
	WaitlistFlowEnum,
	WaitlistStatus,
	useJoinWaitlistMutation,
} from './Waitlist.queries';
import schema from './WatilistForm.schema';

type WaitlistFormData = {
	name: string;
	email: string;
	org_name: string;
	twitter_profile: string;
	custom_gdpr: boolean;
};

const WaitlistForm = () => {
	const { account, connectedWallet } = useWeb3Onboard();
	const { spacings } = useTheme();
	const queryClient = useQueryClient();

	const { mutate: joinWaitlist, isLoading: isJoiningWaitlist } =
		useJoinWaitlistMutation();

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<WaitlistFormData>({
		defaultValues: {
			name: '',
			email: '',
			org_name: '',
			twitter_profile: '',
			custom_gdpr: false,
		},
		resolver: yupResolver(schema),
	});

	const onSubmit: SubmitHandler<WaitlistFormData> = async (values) => {
		if (account && connectedWallet) {
			const { name, email, org_name, twitter_profile, custom_gdpr } = values;

			joinWaitlist(
				{
					name,
					email,
					org_name,
					twitter_profile,
					account,
					wallet_name: connectedWallet?.label,
					custom_gdpr,
				},
				{
					onSuccess: () => {
						reset();

						// Update waitlist flow
						const waitlist = queryClient.getQueryData<WaitlistStatus>(
							queryKeys.waitlist.all
						);

						queryClient.setQueryData(queryKeys.waitlist.all, {
							...waitlist,
							flow: WaitlistFlowEnum.UNDER_REVIEW,
						});
					},
				}
			);
		}
	};

	return (
		<FormContainer onSubmit={handleSubmit(onSubmit)}>
			<Stack gutter={spacings[30]}>
				<Stack gutter={spacings[30]}>
					<TextInput
						label='Name'
						placeholder='John Doe'
						{...register('name')}
						error={errors.name?.message}
						fieldSize='md'
					/>
					<TextInput
						label='Email'
						placeholder='john.doe@pudgypenguins.com'
						{...register('email')}
						error={errors.email?.message}
						fieldSize='md'
					/>
					<TextInput
						label='Organisation name'
						placeholder='Pudgy Penguins'
						{...register('org_name')}
						error={errors.org_name?.message}
						fieldSize='md'
					/>
					<TextInput
						label='Twitter Profile'
						{...register('twitter_profile')}
						placeholder='https://twitter.com/pudgypenguins'
						error={errors.twitter_profile?.message}
						fieldSize='md'
					/>
					<CheckboxInput
						type='checkbox'
						{...register('custom_gdpr')}
						error={errors.custom_gdpr?.message}
					>
						<Typography size='sm'>
							I agree to receive email communications from Coinshift.
						</Typography>
					</CheckboxInput>
					<Stack alignItems='center' gutter={spacings[20]}>
						<Button
							color='primary'
							type='submit'
							style={{ justifySelf: 'center' }}
							isLoading={isJoiningWaitlist}
							disabled={isJoiningWaitlist}
						>
							Request Access
						</Button>

						<p>We will email you our response within 24 hours.</p>
					</Stack>
				</Stack>
			</Stack>
		</FormContainer>
	);
};

export default WaitlistForm;
