import Divider from 'components/Divider';
import Img from 'components/Img';
import { ExternalLink } from 'components/Link';
import Row from 'components/Row';
import Stack from 'components/Stack';
import Typography from 'components/Typography';
import { ReactNode } from 'react';
import { useTheme } from 'styled-components';
import { rem } from 'styles';

export type FooterProps = {
	children?: ReactNode;
};

const Footer = () => {
	const { spacings } = useTheme();
	return (
		<>
			<Divider />
			<Row
				justifyContent='flex-start'
				alignItems='flex-start'
				maxWidth={rem(1160)}
				margin='auto'
				padding={`${spacings[30]} ${spacings[30]} ${spacings[80]}`}
			>
				<Stack flex={4} gutter={spacings[20]}>
					<Stack gutter={spacings[10]}>
						<Img
							src={'/assets/logos/coinshift.svg'}
							alt='coinshift'
							style={{ maxWidth: spacings[128] }}
						/>
						<Typography color='textSecondary' size='sm'>
							&copy; Copyright 2022, Coinshift Inc.
						</Typography>
					</Stack>
				</Stack>

				<div style={{ flex: 4 }} />

				<Stack gutter={spacings[20]} flex={3}>
					<Typography
						variant='h4'
						color='textPrimary'
						size='lg'
						fontWeight='semibold'
					>
						Company
					</Typography>
					<Stack gutter={spacings[10]}>
						<ExternalLink href='https://coinshift.xyz'>Website</ExternalLink>
						<ExternalLink href='https://blog.coinshift.xyz'>Blog</ExternalLink>
						<ExternalLink href='https://docs.coinshift.xyz'>Docs</ExternalLink>
					</Stack>
				</Stack>
				<Stack gutter={spacings[20]} flex={3}>
					<Typography
						variant='h4'
						color='textPrimary'
						size='lg'
						fontWeight='semibold'
					>
						Connect With Us
					</Typography>
					<Stack gutter={spacings[10]}>
						<Row rowGap={spacings[10]}>
							<Img src={'/assets/logos/twitter.svg'} alt='twitter' />
							<ExternalLink href='https://twitter.com/0xCoinshift'>
								Twitter
							</ExternalLink>
						</Row>
						<Row rowGap={spacings[10]}>
							<Img src={'/assets/logos/discord.svg'} alt='discord' />
							<ExternalLink href='https://discord.gg/coinshift'>
								Discord
							</ExternalLink>
						</Row>
						<Row rowGap={spacings[10]}>
							<Img src={'/assets/logos/linkedin.svg'} alt='linkedIn' />
							<ExternalLink href='https://www.linkedin.com/company/coinshift-finance/'>
								LinkedIn
							</ExternalLink>
						</Row>
						<Row rowGap={spacings[10]}>
							<Img src={'/assets/logos/youtube.svg'} alt='discord' />
							<ExternalLink href='https://www.youtube.com/@coinshift929'>
								YouTube
							</ExternalLink>
						</Row>
						<Row rowGap={spacings[10]}>
							<Img src={'/assets/logos/notificationBell.svg'} alt='discord' />
							<ExternalLink href='https://share.hsforms.com/117SnMHs1T4yF7GT-y_gfxQdsofm'>
								Newsletter
							</ExternalLink>
						</Row>
					</Stack>
				</Stack>
			</Row>
		</>
	);
};

export { Footer };
