import { FullPageContainer } from 'components/Container';
import Header from 'components/Header';
import useHubspotTracking from 'hooks/useHubspotTracking';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ReactElement } from 'react';
import Login from 'screens/login';

export default function HomePage() {
	const { asPath } = useRouter();
	const { setPathPageView } = useHubspotTracking();
	setPathPageView(asPath);

	return <Login />;
}

HomePage.getLayout = function getLayout(page: ReactElement) {
	return (
		<>
			<Head>
				<title>Coinshift</title>
				<meta
					name='description'
					content='Treasury Management for DAOs and Companies'
				/>
				<link rel='icon' href='/favicon.ico' />
			</Head>
			<FullPageContainer>
				<Header />
				{page}
			</FullPageContainer>
		</>
	);
};
