import styled, { css, keyframes } from 'styled-components';
import { SpinnerProps, SPINNER_SIZES } from './Spinner';

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledSpinner = styled.span<SpinnerProps>(
	({ theme, size, color, speed }) => {
		return css`
			height: ${size && SPINNER_SIZES[size]};
			width: ${size && SPINNER_SIZES[size]};
			display: inline-block;
			border-width: calc(${size && SPINNER_SIZES[size]} / 6);
			border-color: ${color && theme.colors[color][900]};
			border-style: solid;
			border-radius: ${theme.spacings.max};
			border-bottom-color: ${color && theme.colors[color][100]};
			animation: ${spinAnimation} ${speed} linear infinite;
			flex-shrink: 0;
		`;
	}
);
