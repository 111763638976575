import yup from 'utils/yup';

const schema = yup.object().shape({
	name: yup
		.string()
		.required('Name is required')
		.min(3, 'Name must be at least 3 characters')
		.max(256, 'Name is too long'),
	email: yup
		.string()
		.email('Invalid email address')
		.required('Email is required'),
	org_name: yup
		.string()
		.required('Organisation name is required')
		.min(3, 'Organisation name must be at least 3 characters')
		.max(256, 'Organisation name is too long'),
	twitter_profile: yup.string().required('Twitter Profile is required'),
	role: yup
		.object()
		.shape({
			label: yup.string(),
			value: yup.number(),
		})
		.optional(),
	custom_gdpr: yup
		.bool()
		.oneOf([true], 'Subscribing to email communications is required'),
});

export default schema;
