import styled from 'styled-components';
import {
	breakpoints,
	colors,
	fontSizes,
	fontWeights,
	rem,
	spacings,
} from 'styles';

// TODO: Replace text components with <Text />
export const MainTitle = styled.h1`
	font-size: ${fontSizes['3xl']};
	font-weight: ${fontWeights.bold};
	color: ${colors.grey[900]};
	text-align: center;
	max-width: ${rem(880)};
	margin: auto;

	@media ${breakpoints.tablet} {
		font-size: ${fontSizes['6xl']};
	}
`;

export const MainSubtitle = styled.p`
	font-size: ${fontSizes['md']};
	color: ${colors.grey[900]};
	text-align: center;
	margin: ${spacings[30]} auto ${spacings[60]};
	max-width: ${rem(500)};

	@media ${breakpoints.tablet} {
		font-size: ${fontSizes['lg']};
	}
`;

export const LoginContainer = styled.div`
	text-align: center;
	padding-top: 0;
	max-width: ${rem(1000)};
	margin: auto;
`;

export const Card = styled.div`
	width: ${rem(300)};
	height: ${rem(120)};
	background: ${colors.white[100]};
	border: ${spacings[1]} solid ${colors.primary[100]};
	box-sizing: border-box;
	border-radius: ${spacings[8]};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

// TODO: Replace with Text
export const CardTitle = styled.p`
	font-size: ${fontSizes.md};
	margin-bottom: ${spacings[12]};
`;

export const CardSubtitle = styled.p`
	font-size: ${fontSizes['2.5xl']};
	font-weight: ${fontWeights.semibold};
	color: ${colors.primary[500]};
`;
