import {
	useWaitlistQuery,
	WaitlistFlowEnum,
} from 'common/waitlist/Waitlist.queries';
import WaitlistForm from 'common/waitlist/WaitlistForm';
import Button from 'components/Button';
import ConnectButton from 'components/ConnectButton';
import Footer from 'components/Footer';
import Img from 'components/Img';
import { ExternalLink, InternalLink } from 'components/Link';
import Row from 'components/Row';
import Spinner from 'components/Spinner';
import Stack from 'components/Stack';
import Typography from 'components/Typography';
import { events } from 'constants/analytics';
import paths from 'constants/paths';
import { useWeb3Onboard } from 'hooks';
import { useAnalytics } from 'hooks/useAnalytics';
import { useRouter } from 'next/router';
import { spacings } from 'styles/constants/spacings';
import { addQueryParams } from 'utils/axios';
import {
	useAuthTokenQuery,
	useLoginMutation,
	useStatsQuery,
} from './Login.queries';
import {
	Card,
	CardSubtitle,
	CardTitle,
	LoginContainer,
	MainSubtitle,
	MainTitle,
} from './Login.styles';

// waitlist
const isWaitlistEnabled = process.env.NEXT_PUBLIC_WAITLIST_ENABLED === 'true';

const LoginSignupFlow = () => {
	const { query, push } = useRouter();
	const { account, connectedWallet } = useWeb3Onboard();
	const { track } = useAnalytics();

	const { refetch: verifyAuthToken, isFetching: isVerifyingAuthToken } =
		useAuthTokenQuery();

	const { mutate: logIn, isLoading: isLogIn } = useLoginMutation({
		redirectUrl: paths.organisations.root,
	});

	const isLoading = isVerifyingAuthToken || isLogIn;

	const logInUser = async () => {
		track(events.user.login, {
			walletAddress: account,
			walletName: connectedWallet?.label,
		});
		const { data } = await verifyAuthToken();

		if (data?.status) push(paths.organisations.root);
		else logIn();
	};

	let signupURL = '/signup';

	if (query.referral_code) {
		signupURL = addQueryParams(signupURL, {
			referral_code: query.referral_code,
		});
	}

	return (
		<Stack alignItems={'center'} gutter={spacings[30]}>
			<Row justifyContent='center' rowGap={spacings[10]}>
				<Button
					onClick={() => logInUser()}
					isLoading={isLoading}
					disabled={isLoading}
				>
					Log In
				</Button>
			</Row>
			<Row justifyContent='center' rowGap={spacings[4]}>
				<Typography variant='span'>New to Coinshift?</Typography>
				<InternalLink href={signupURL}>
					<Button variant='text'>Sign up.</Button>
				</InternalLink>
			</Row>
		</Stack>
	);
};

const UserLoginFlow = () => {
	const { account } = useWeb3Onboard();

	const { data: waitlist, isFetching } = useWaitlistQuery();

	if (!account) {
		return (
			<Row justifyContent='center'>
				<ConnectButton />
			</Row>
		);
	}

	if (!isWaitlistEnabled) {
		return <LoginSignupFlow />;
	}

	if (isFetching)
		// waitlist
		return (
			<Row justifyContent='center'>
				<Spinner />
			</Row>
		);

	if (waitlist?.flow === WaitlistFlowEnum.JOIN) {
		return (
			<Row justifyContent='center' style={{ textAlign: 'left' }}>
				<WaitlistForm />
			</Row>
		);
	} else if (waitlist?.flow === WaitlistFlowEnum.UNDER_REVIEW) {
		return (
			<MainSubtitle>
				<Stack>Thank you for requesting access for v2.</Stack>
				<Stack>We will email you our response within 24 hours.</Stack>
			</MainSubtitle>
		);
	}

	// login or signup
	return <LoginSignupFlow />;
};

const Login = () => {
	const { data: waitlist } = useWaitlistQuery();
	const { data: stats, isError, isLoading } = useStatsQuery();

	const renderSpinner = () => <Spinner />;

	return (
		<>
			<LoginContainer>
				<MainTitle>Treasury Management for Crypto Organisations</MainTitle>

				{waitlist?.flow === WaitlistFlowEnum.JOIN ? (
					<MainSubtitle>
						<Stack>
							To request early access to Coinshift v2, <br /> please complete
							the form below. <br /> <br /> Joining an existing organisation? To
							get access, ask an admin to invite you instead of filling in this
							form.
						</Stack>
					</MainSubtitle>
				) : waitlist?.flow === WaitlistFlowEnum.UNDER_REVIEW ? null : (
					<MainSubtitle>Connect your wallet to log in.</MainSubtitle>
				)}

				<Stack gutter={spacings[80]}>
					<UserLoginFlow />

					<Row rowGap={spacings[4]} justifyContent='center'>
						<Typography fontWeight='bold' size='xl'>
							Secured by
						</Typography>
						<ExternalLink href='https://safe.global'>
							<Row rowGap={spacings[2]} justifyContent='center'>
								<Img src={'/assets/logos/safe.png'} alt='safe' width={24} />
								<Typography fontWeight='bold' size='xl'>
									Safe
								</Typography>
							</Row>
						</ExternalLink>
					</Row>
				</Stack>

				{!isError && (
					<Row
						rowGap={spacings[40]}
						margin={`${spacings[20]} 0 ${spacings[80]} 0 `}
						flexWrap={'wrap'}
						justifyContent={'center'}
					>
						<Card>
							<CardTitle>Total Accounts</CardTitle>
							<CardSubtitle>
								{isLoading ? renderSpinner() : stats?.accCount}
							</CardSubtitle>
						</Card>
						<Card>
							<CardTitle>Total Organisations</CardTitle>
							<CardSubtitle>
								{isLoading ? renderSpinner() : stats?.orgCount}
							</CardSubtitle>
						</Card>
						<Card>
							<CardTitle>Total Members</CardTitle>
							<CardSubtitle>
								{isLoading ? renderSpinner() : stats?.userCount}
							</CardSubtitle>
						</Card>
					</Row>
				)}
			</LoginContainer>
			<Footer />
		</>
	);
};

export default Login;
