import React from 'react';
import { CSSProperties } from 'styled-components';
import { rem } from 'styles';
import { ThemeColors } from 'styles/constants/colors';
import { StyledSpinner } from './Spinner.styles';

export const SPINNER_SIZES = {
	sm: rem(20),
	md: rem(28),
	lg: rem(36),
};

export interface SpinnerProps {
	size?: keyof typeof SPINNER_SIZES;
	color?: keyof ThemeColors;
	speed?: string;
	style?: CSSProperties;
}

const Spinner = ({
	size = 'md',
	color = 'primary',
	speed = '1000ms',
	...otherProps
}: SpinnerProps) => {
	return (
		<StyledSpinner color={color} size={size} speed={speed} {...otherProps} />
	);
};

export { Spinner };
